<template>
  <modal-dialog
      @dialogConfirm="handleConfirm"
      @dialogClose="dialogClose"
      :dialogData="dialogConfig"
  >
    <div slot="content">
      <el-form :model="formData" ref="form" label-width="100px" class="demo-ruleForm">
        <el-form-item label="业务类型" prop="business_type">
          <el-select v-model="questionData.business_type_id" :disabled="isReadOnly" filterable placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <single-choice-content
          :single-data="questionData"
          :is-read-only="isReadOnly"
          :primary-id="primaryId"
          ref='singleContent'
          @form-data="getDialogFormData"
          v-if="questionType === 'single_choice'"
      ></single-choice-content>
      <multiple-choice-content
          ref='multipleContent'
          :multiple-data="questionData"
          :is-read-only="isReadOnly"
          :primary-id="primaryId"
          @form-data="getDialogFormData"
          v-else-if="questionType === 'multiple_choice'"
      ></multiple-choice-content>
      <case-analysis-question
          ref='caseContent'
          :caseData="questionData"
          :is-read-only="isReadOnly"
          :primary-id="primaryId"
          @cur-data="getDialogFormData"
          v-else-if="questionType === 'case_analysis'"
      ></case-analysis-question>
      <essay-question-content
          ref='essayContent'
          :essay-data="questionData"
          :primary-id="primaryId"
          :is-read-only="isReadOnly"
          @form-data="getDialogFormData"
          v-else-if="questionType === 'essay_questions'|| questionType === 'short'"
      ></essay-question-content>
      <judge-question
          ref='judgeContent'
          :judge-data="questionData"
          :is-read-only="isReadOnly"
          :primary-id="primaryId"
          @form-data="getDialogFormData"
          v-else-if="questionType === 'judge'"
      ></judge-question>
    </div>
  </modal-dialog>
</template>

<script>
import modalDialog from '@/components/ModalDialog';
import SingleChoiceContent from './SingleChoiceContnet';
import MultipleChoiceContent from './MultipleChoiceContent';
import EssayQuestionContent from './EssayQuestionContent';
import CaseAnalysisQuestion from './CaseAnalysisQuestion';
import JudgeQuestion from './JudgeQuestion';

const TYPE_SINGLE = "single_choice";
const TYPE_MULTIPLE = "multiple_choice";
const TYPE_ESSAY = "essay_questions";
const TYPE_CASE = "case_analysis";
const TYPE_JUDGE = "judge";
const TYPE_SHORT = "short";

export default {
  name: "index",
  components: {
    modalDialog,
    SingleChoiceContent,
    MultipleChoiceContent,
    EssayQuestionContent,
    CaseAnalysisQuestion,
    JudgeQuestion
  },
  props: {
    dialogConfig: {
      type: Object,
      default: () => {
      }
    },

    questionData: {
      type: Object,
      default: () => {
      }
    },

    isReadOnly: {
      type: Boolean,
      default: function () {
        return false;
      }
    },

    questionType: {
      type: String,
      default: function () {
        return 'single_choice'
      }
    },

    primaryId: {
      type: String,
      default: function () {
        return ""
      }
    }
  },

  data() {
    return {
      formData: {},
      options: []
    }
  },
  watch: {
    questionData: {
      handler() {
        this.queryBusinessTypeList()
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    async queryBusinessTypeList() {
      let {list} = await this.$api.getBusinessTypeList({})
      this.options = list.data
    },
    //确定
    handleConfirm() {
      switch (this.questionType) {
        case TYPE_SINGLE:
          this.$refs.singleContent.validateData().then((res) => {
            this.$emit("dialog-confirm", res);
          }).catch((err) => {
            this.$message(err);
          });
          break;
        case TYPE_MULTIPLE:
          this.$refs.multipleContent.validateData().then((res) => {
            this.$emit("dialog-confirm", res);
          }).catch((err) => {
            this.$message(err);
          });
          break;
        case TYPE_JUDGE:
          this.$refs.judgeContent.validateData().then((res) => {
            this.$emit("dialog-confirm", res);
          }).catch((err) => {
            this.$message(err);
          });
          break;
        case TYPE_CASE:
          this.$refs.caseContent.validateData().then((res) => {
            this.$emit("dialog-confirm", res);
          }).catch((err) => {
            this.$message(err);
          });
          break;
        case TYPE_ESSAY:
          this.$refs.essayContent.validateData().then((res) => {
            this.$emit("dialog-confirm", res);
          }).catch((err) => {
            this.$message(err);
          });
          break;
      }

      // this.$emit("dialog-confirm", this.formData);
    },
    //取消
    dialogClose() {
      this.resetForm();
      this.$emit("dialog-cancel");
    },

    //获取到弹框表单信息
    getDialogFormData(value) {
      this.formData = value;
    },

    resetForm() {
      switch (this.questionType) {
        case TYPE_SINGLE:
          this.$nextTick(() => {
            this.$refs.singleContent.resetForm();
          });
          break;
        case TYPE_MULTIPLE:

          this.$nextTick(() => {
            this.$refs.multipleContent.resetForm();
          });
          break;
        case TYPE_ESSAY:
          this.$nextTick(() => {
            this.$refs.essayContent.resetForm();
          });
          break;
        case TYPE_CASE:
          this.$nextTick(() => {
            this.$refs.caseContent.resetForm();
          });
          break;

        case TYPE_JUDGE:
          this.$nextTick(() => {
            this.$refs.judgeContent.resetForm();
          });
          break;
        case TYPE_SHORT:
          this.$nextTick(() => {
            this.$refs.essayContent.resetForm();
          });
          break;
      }
    },
  }
}
</script>

<style scoped>

</style>