<template>
    <el-row class="table-search">
        <el-form :inline="true" :model="formData" class="demo-form-inline">
            <slot name="last-column"></slot>
            <el-form-item v-for="(item,index) in searchConfig" :label="item.title" :key="index">
                <el-autocomplete
                        v-model="formData[item.value]"
                        :placeholder="'请输入'+item.title"
                        :fetch-suggestions="querySearch"
                        :trigger-on-focus="false"
                        v-if="item.type==='input'"
                        @select="handleSelect"
                        clearable
                ></el-autocomplete>

                <!--<el-select v-model="formData.police_type_code"-->
                <!--filterable-->
                <!--clearable-->
                <!--v-if="item.type === 'select'"-->
                <!--:placeholder="`请输入或选择${item.title}`"-->
                <!--&gt;-->
                <!--<el-option v-for="selectItem in item.selectList"-->
                <!--:key="selectItem.value"-->
                <!--:label="selectItem.label"-->
                <!--:value="selectItem.value"-->
                <!--&gt;</el-option>-->
                <!--</el-select>-->

                <el-select v-model="formData[item.value]" clearable  :placeholder=" item.placeholder? item.placeholder : '请输入'+item.title " v-if="item.type === 'select'">
                    <el-option
                            v-for="selectItem in item.selectList"
                            :key="selectItem.id"
                            :label="selectItem.text"
                            :value="selectItem.id"
                    />
                </el-select>

                <el-date-picker
                        v-model="timeValue"
                        v-if="item.type === 'date'"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="考试开始时间"
                        end-placeholder="考试开始时间"
                        value-format="yyyy-MM-dd">
                </el-date-picker>
                <slot name="last-item">

                </slot>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSearchClick">搜索</el-button>
                <el-button @click="onResetSearchClick" style="margin-left: 10px">重置</el-button>
            </el-form-item>
        </el-form>
    </el-row>

</template>

<script>
    export default {
        name: "SearchBox",
        props: {
            searchList: {
                type: Array,
                default: () => []
            },

            searchConfig: {
                type: Array,
                default: () => []
            },

            restaurants: {
                type: Array,
                default: () => []
            },

            isShowData: {
                type: Boolean,
                default: false
            },

            isShowSelect: {
                type: Boolean,
                default: false
            },

            dataLabel: {
                type: String,
                default: "创建时间"
            }
        },
        data() {
            return {
                formData: {},
                resultList: [],
                timeValue: "",
                typeList: [
                    {
                        id: "0",
                        display_name: "启用"
                    },
                    {
                        id: "-1",
                        display_name: "禁用"
                    }
                ]
            }
        },

        mounted() {
        },

        methods: {
            onSearchClick() {

                if (this.timeValue.length > 0) {
                    this.formData.start_time = this.timeValue[0];
                    this.formData.end_time = this.timeValue[1];
                }

                let formData = {};
                for (let key in this.formData) {
                    if (this.formData.hasOwnProperty(key)) {
                        if (this.formData[key] !== "") {
                            formData[key] = this.formData[key];
                        }
                    }
                }

                this.$emit('search', formData);
            },

            onResetSearchClick() {
                this.formData = {};
                this.timeValue = [];

                this.$emit('reset-search')
            },
            querySearch(queryString, cb) {
                let restaurants = this.restaurants;

                let results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
                // 调用 callback 返回建议列表的数据
                cb(results);
            },

            createFilter(queryString) {
                return (restaurant) => {
                    // return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                    return restaurant.value.match(queryString);
                };
            },

            handleSelect(item) {
                console.log(item);
            }
        }
    }
</script>

<style scoped>

</style>