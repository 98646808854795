<template>
    <div class="el-row">
        <el-form
                :model="formData"
                :rules="rules"
                label-width="80px"
                ref="singleForm"
        >

            <el-form-item label="题目" prop="topic">
                <el-input v-model="formData.topic"
                          type="textarea"
                          class="el-col-11"
                          :autosize="{ minRows: 2, maxRows: 5}"
                          placeholder="请输入题目"
                          :readonly="isReadOnly"
                ></el-input>
            </el-form-item>

            <div v-for="(item, index) in formData.options" :key="index" style="margin-left: 50px">
                <el-form-item align="center"
                              :label="`选项${item.option_no}`"
                              :prop="`options[${index}].option`"
                              :rules="{ required: true, message: '请输入选项内容', trigger: 'blur' }"
                >
                    <div class="el-col-21">
                        <el-input v-model="item.option"
                                  type="textarea"
                                  :autosize="{ minRows: 2, maxRows: 4}"
                                  :placeholder="`请输入选项${item.option_no}内容`"
                                  :readonly="isReadOnly"
                        ></el-input>
                    </div>

                    <!--<i v-if="!isReadOnly && index === formData.options.length - 1"-->
                       <!--class="el-icon-plus chart-color line-height-54"-->
                       <!--@click="onAddQuestionClick()"></i>-->
                    <div style="margin-top: 12px">
                    <el-button v-if="!isReadOnly " type="danger" size="mini" icon="el-icon-minus" @click="onDeleteQuestionAnswer(index)" style="float: left;margin-left: 5px"></el-button>
                        <!--<i v-if="!isReadOnly && index === formData.options.length - 1"-->
                        <!--class="el-icon-plus chart-color line-height-54"-->
                        <!--@click="onAddQuestionClick()"></i>-->
                    <el-button v-if="!isReadOnly && index === formData.options.length - 1" type="primary" @click="onAddQuestionClick()" size="mini" style="float: left" icon="el-icon-plus"></el-button>
                    </div>

                </el-form-item>
            </div>

            <!--<el-form-item label="选项" align="center" :prop="`formData.options[${index}].name`">-->
            <!--<el-col :span="24" v-for="(item, index) in formData.options" :key="index" style="margin-top: 12px">-->
            <!--<label class="el-col-2 ">{{ item.option_no }}</label>-->
            <!--<div class="el-col-20">-->
            <!--<el-input v-model="item.option"-->
            <!--type="textarea"-->
            <!--:autosize="{ minRows: 2, maxRows: 4}"-->
            <!--:readonly="isReadOnly"-->
            <!--&gt;</el-input>-->
            <!--</div>-->
            <!--</el-col>-->
            <!--</el-form-item>-->

            <el-form-item label="正确答案" prop="correct_answer">
                <el-radio-group v-model="formData.correct_answer">
                    <el-radio v-for="(item, index) in formData.options"
                              :label="item.option_no" :key="index"
                              :disabled="isReadOnly">
                        {{ item.option_no }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item label="解析">
                <el-input v-model="formData.answer_points"
                          type="textarea"
                          class="el-col-11"
                          :readonly="isReadOnly"
                          :autosize="{ minRows: 2, maxRows: 5}"
                          placeholder="请输入解析内容"
                ></el-input>
            </el-form-item>

            <el-form-item label="分数" prop="score">
                <el-input-number v-model="formData.score"
                                 @change="onPointChange"
                                 :min="1"
                                 :max="10"
                                 label="描述文字"
                                 :disabled="isReadOnly"
                ></el-input-number>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>

    export default {
        name: "SingleChoiceContent",
        props: {
            isReadOnly: {
                type: Boolean,
                default: false
            },
            primaryId: {
                type: String,
                default: ""
            },
            singleData: {
                type: Object,
                default: () => {
                }
            },
        },
        data() {
            return {
                formData: {
                    id: "",
                    question_no: "",
                    topic: "",
                    correct_answer: "A",
                    status: "0",
                    score: 0,
                    answer_points: "",
                    options: [
                        {
                            id: "",
                            question_id: "",
                            option_no: "A",
                            option: "",
                            created_at: "",
                            updated_at: ""
                        },
                        {
                            id: "",
                            question_id: "",
                            option_no: "B",
                            option: "",
                            created_at: "",
                            updated_at: ""
                        },
                        {
                            id: "",
                            question_id: "",
                            option_no: "C",
                            option: "",
                            created_at: "",
                            updated_at: ""
                        },
                        {
                            id: "",
                            question_id: "",
                            option_no: "D",
                            option: "",
                            created_at: "",
                            updated_at: ""
                        },
                    ],
                    created_at: "",
                    updated_at: "",
                    question_id: "",
                },

                rules: {
                    topic: [
                        {required: true, message: '请输入题目内容', trigger: 'blur'}
                    ],

                    correct_answer: [
                        {required: true, message: '请选择正确答案', trigger: 'change'}
                    ],
                    score: [
                        {required: true, message: '请输入题目分数', trigger: 'change'}
                    ]
                },

                curQbId: "",

            }
        },

        methods: {
            //题库状态按钮
            onBankStatusChange(value) {
                this.formData.status = value;
            },

            //分数
            onPointChange(value) {
                this.formData.score = value;
            },

            validateData() {
                return new Promise((resolve, reject) => {
                    this.$refs.singleForm.validate((valid) => {
                        if (valid) {
                            // this.$emit('form-data', this.formData, true);
                            resolve(this.formData);
                        } else {
                            reject('请填写必填项');
                        }
                    });
                });
            },

            //新增选项
            onAddQuestionClick() {


                if (this.formData.options.length >= 4) {
                    this.$message('选项最多不能超过四个');
                    return;
                }

                let itemObject = {
                    id: "",
                    question_id: "",
                    option_no: "",
                    option: "",
                    created_at: "",
                    updated_at: ""
                };

                this.formData.options.push(itemObject);
                this.answerSort();
            },

            answerSort(){
                let optionList = ['A', 'B', 'C', 'D'];
                for (let i = 0; i < this.formData.options.length; i++) {
                    let item = this.formData.options[i];
                    item.option_no = optionList[i];
                }
            },

            //减少选项
            onDeleteQuestionAnswer(index) {
                if (this.formData.options.length <= 1) {
                    this.$message('选项最少为一个');
                    return;
                }
                this.formData.options.splice(index, 1);
                this.answerSort();
            },

            optionVerify(rule, value, callback) {
                for (let item in this.formData.options) {
                    let option = item.option;
                    if (rule.required && !option) {
                        callback(new Error(rule.message))
                    } else {
                        callback()
                    }
                }
            },

            resetForm() {
                this.formData = {
                    id: "",
                    question_no: "",
                    topic: "",
                    correct_answer: "A",
                    status: "0",
                    score: 0,
                    answer_points: "",
                    options: [
                        {
                            id: "",
                            question_id: "",
                            option_no: "A",
                            option: "",
                            created_at: "",
                            updated_at: ""
                        },
                        {
                            id: "",
                            question_id: "",
                            option_no: "B",
                            option: "",
                            created_at: "",
                            updated_at: ""
                        },
                        {
                            id: "",
                            question_id: "",
                            option_no: "C",
                            option: "",
                            created_at: "",
                            updated_at: ""
                        },
                        {
                            id: "",
                            question_id: "",
                            option_no: "D",
                            option: "",
                            created_at: "",
                            updated_at: ""
                        },
                    ],
                    created_at: "",
                    updated_at: "",
                    question_id: "",
                };

                this.$refs['singleForm'].resetFields();
            }
        },
        watch: {
            // formData: {
            //     handler(newVal) {
            //         this.$emit('form-data', newVal);
            //     },
            //     deep: true
            // },

            singleData: {
                handler(newVal) {
                    if (JSON.stringify(newVal) !== {}) {
                        let singleData = newVal;
                        for (let key in singleData) {
                            let item = singleData[key];
                            this.formData[key] = item;
                        }

                    }
                },
                deep: true,
                immediate: true
            }
        }
    }
</script>

<style scoped>
    .text-center {
        text-align: center;
    }
</style>