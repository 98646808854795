<template>
    <div class="row">
        <el-form
                label-width="80px"
                :rules="rules"
                :model="formData"
                ref="essayForm"
                :disabled="isReadOnly">

            <!--<el-form-item label="题库状态">-->
            <!--<el-radio-group v-model="formData.status"-->
            <!--@change="onBankStatusChange">-->
            <!--<el-radio label="0">正常</el-radio>-->
            <!--<el-radio label="-1">禁用</el-radio>-->
            <!--</el-radio-group>-->
            <!--</el-form-item>-->

            <el-form-item label="题目" prop="topic">
                <el-input class="el-col-11"
                          type="textarea"
                          v-model="formData.topic"
                          :autosize="{ minRows: 2, maxRows: 5}"
                          :disabled="isReadOnly"
                          placeholder="请输入题目"
                ></el-input>
            </el-form-item>

            <el-form-item label="答案" >
                <el-input class="el-col-11"
                          type="textarea"
                          v-model="formData.options.correct_answer"
                          :autosize="{ minRows: 2, maxRows: 5}"
                          :disabled="isReadOnly"
                          placeholder="请输入答案"
                ></el-input>
            </el-form-item>

            <el-form-item label="解析">
                <el-input class="el-col-11"
                          type="textarea"
                          v-model="formData.options.answer_points"
                          :autosize="{ minRows: 1, maxRows: 5}"
                          placeholder="请输入解析内容"></el-input>
            </el-form-item>

            <el-form-item label="分数" prop="score">
                <el-input-number v-model="formData.options.score" :min="1"
                                 :max="100"
                                 label="描述文字"></el-input-number>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default {
        name: "EssayQuestionContent",
        data() {
            return {
                formData: {
                    id: "",
                    question_no: "",
                    topic: "",
                    score: "1",
                    created_at: "",
                    updated_at: "",
                    question_id: "",
                    options: {
                        id: "",
                        question_id: "",
                        correct_answer: "",
                        answer_points: "",
                        score: "1",
                        created_at: "",
                        updated_at: "",
                    }
                },
                rules: {
                    topic: [
                        {required: true, message: '请输入题目内容', trigger: 'blur'}
                    ],
                    correct_answer: [
                        {required: true, message: '请选择正确答案', trigger: 'blur'}
                    ],
                    score: [
                        {required: true, message: '请输入题目分数', trigger: 'change'}
                    ]
                }
            }
        },

        props: {
            // props: {
            //     isReadOnly: Boolean,
            //     data: Object,
            //     primaryId: String
            // },
            essayData: {
                type: Object,
                default: () => {
                }
            },
            isReadOnly: {
                type: Boolean,
                default: false
            },
            primaryId: {
                type: String,
                default: ""
            }
        },

        methods: {
            //分数
            // onPointChange(value) {
            //     this.formData.score = value;
            // },

            validateData() {
                let options = this.formData.options;
                this.formData.score = options.score;
                // this.$emit('form-data', this.formData);
                return new Promise((resolve, reject) => {
                    this.$refs.essayForm.validate((valid) => {
                        console.log('formData', this.formData);
                        if (valid) {
                            resolve(this.formData);
                            // this.$emit('form-data', this.formData);
                        } else {
                            // this.$message('请填写必填项');
                            reject('请填写必填项');
                        }
                    });
                });
            },

            //重置
            resetForm() {
                this.formData = {
                    id: "",
                    question_no: "",
                    topic: "",
                    score: "1",
                    created_at: "",
                    updated_at: "",
                    question_id: "",
                    options: {
                        id: "",
                        question_id: "",
                        correct_answer: "",
                        answer_points: "",
                        score: "1",
                        created_at: "",
                        updated_at: "",
                    }
                }
            }
        },
        watch: {
            essayData: {
                handler(newVal) {
                    for (let key in newVal) {
                        if (newVal.hasOwnProperty(key)) {
                            this.formData[key] = newVal[key];
                        }
                    }
                },
                deep: true,
                immediate: true
            },

            // formData: {
            //     handler(newVal) {
            //         if (newVal) {
            //             let options = this.formData.options;
            //             this.formData.score = options.score;
            //
            //             this.$emit('form-data', this.formData);
            //         }
            //     },
            //     deep: true
            // },
        }
    }
</script>

<style scoped>

</style>