import { render, staticRenderFns } from "./MultipleChoiceContent.vue?vue&type=template&id=65823aad&scoped=true&"
import script from "./MultipleChoiceContent.vue?vue&type=script&lang=js&"
export * from "./MultipleChoiceContent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65823aad",
  null
  
)

export default component.exports