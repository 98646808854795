<template>
    <div class="row">
        <el-form label-width="80px"
                 :rules="rules"
                 :model="formData"
                 ref="multipleForm"
                 >
            <!--<el-form-item label="题库状态">-->
            <!--<el-radio-group-->
            <!--v-model="formData.status"-->
            <!--@change="onBankStatusChange">-->
            <!--<el-radio label="0">正常</el-radio>-->
            <!--<el-radio label="-1">禁用</el-radio>-->
            <!--</el-radio-group>-->
            <!--</el-form-item>-->

            <el-form-item label="题目" prop="topic">
                <el-input class="col-md-8"
                          type="textarea"
                          v-model="formData.topic"
                          :readonly="isReadOnly"
                          placeholder="请输入题目内容"
                          :autosize="{ minRows: 2, maxRows: 5}">
                </el-input>
            </el-form-item>

            <div  v-for="(item, index) in formData.options" :key="index" style="margin-left: 50px">
                <el-form-item  align="center"
                               :label="`选项${item.option_no}`"
                               :prop="`options[${index}].option`"
                               :rules="{ required: true, message: '请输入选项内容', trigger: 'blur' }"
                >
                    <div class="el-col-22">
                        <el-input v-model="item.option"
                                  type="textarea"
                                  :placeholder="`请输入选项${item.option_no}内容`"
                                  :autosize="{ minRows: 2, maxRows: 4}"
                                  :readonly="isReadOnly"
                        ></el-input>
                    </div>

                    <i v-if="!isReadOnly && index === formData.options.length - 1"
                       class="el-icon-plus chart-color line-height-54"
                       @click="onAddQuestionClick()"></i>

                    <i v-if="!isReadOnly "
                       class="el-icon-delete line-height-54" style="color: red"
                       @click="onDeleteQuestionAnswer(index)"></i>
                </el-form-item>
            </div>

            <el-form-item label="正确答案" prop="correct_answer">
                <el-checkbox-group v-model="correctAnswer" @change="onCorrectAnswerChange">
                    <el-checkbox v-for="(item,index) in formData.options"
                                 :label="item.option_no"
                                 :disabled="isReadOnly"
                                 :key="index"
                    >
                        {{ item.option_no }}
                    </el-checkbox>
                </el-checkbox-group>

            </el-form-item>

            <el-form-item label="解析">
                <el-input class="col-md-8" type="textarea"
                          v-model="formData.answer_points"
                          :readonly="isReadOnly"
                          placeholder="请输入解析内容">
                </el-input>
            </el-form-item>

            <el-form-item label="分数" prop="score">
                <el-input-number v-model="formData.score" @change="onPointChange" :min="1"
                                 :max="100"
                                 label="描述文字"
                                 :disabled="isReadOnly"
                >
                </el-input-number>
            </el-form-item>

        </el-form>
    </div>
</template>

<script>
    export default {
        name: "MultipleChoiceContent",
        data() {
            return {
                formData: {
                    id: "",
                    question_no: "",
                    topic: "",
                    correct_answer: "",
                    status: "0",
                    score: 1,
                    answer_points: "",
                    options: [
                        {
                            question_id: "",
                            option_no: "A",
                            option: "",
                            created_at: "",
                            updated_at: ""
                        },
                        {
                            question_id: "",
                            option_no: "B",
                            option: "",
                            created_at: "",
                            updated_at: ""
                        },
                        {
                            question_id: "",
                            option_no: "C",
                            option: "",
                            created_at: "",
                            updated_at: ""
                        },
                        {
                            question_id: "",
                            option_no: "D",
                            option: "",
                            created_at: "",
                            updated_at: ""
                        },

                    ],
                },

                rules: {
                    topic: [
                        {required: true, message: '请输入题目内容', trigger: 'blur'}
                    ],

                    correct_answer: [
                        {required: true, message: '请选择正确答案', trigger: 'blur'}
                    ],
                    score: [
                        {required: true, message: '请输入题目分数', trigger: 'change'}
                    ]
                },

                correctAnswer: []
            }
        },
        props: {
            isReadOnly: {
                type: Boolean,
                default: false
            },
            primaryId: {
                type: String,
                default: ""
            },
            multipleData: {
                type: Object,
                default: () => {
                }
            }
        },

        mounted() {

        },
        methods: {
            //题库状态按钮
            onBankStatusChange(value) {
                this.formData.status = value;
            },

            //分数
            onPointChange(value) {
                this.formData.score = value;
            },

            onCorrectAnswerChange(value) {
                this.formData.correct_answer = value.join("");
            },

            validateData() {
                let correctAnswer = this.correctAnswer.join("");
                if (correctAnswer) {
                    this.formData.correct_answer = correctAnswer;
                }

                return new Promise((resolve, reject)=>{
                    this.$refs.multipleForm.validate((valid) => {
                        console.log('formData', this.formData);
                        if (valid) {
                            resolve(this.formData);
                            // this.$emit('form-data', this.formData);
                        } else {
                            // this.$message('请填写必填项');
                            reject('请填写必填项');
                        }
                    });
                });
            },

            //新增选项
            onAddQuestionClick() {
                if (this.formData.options.length >= 4) {
                    this.$message('选项最多不能超过四个');
                    return;
                }

                let itemObject = {
                    id: "",
                    question_id: "",
                    option_no: "",
                    option: "",
                    created_at: "",
                    updated_at: ""
                };

                this.formData.options.push(itemObject);
                this.answerSort();
            },

            answerSort(){
                let optionList = ['A', 'B', 'C', 'D'];
                for (let i = 0; i < this.formData.options.length; i++) {
                    let item = this.formData.options[i];
                    item.option_no = optionList[i];
                }
            },

            //减少选项
            onDeleteQuestionAnswer(index) {
                if (this.formData.options.length <= 1) {
                    this.$message('选项最少为一个');
                    return;
                }
                this.formData.options.splice(index, 1);
                this.answerSort();
            },

            resetForm() {
                this.formData = {
                    id: "",
                    question_no: "",
                    topic: "",
                    correct_answer: [],
                    status: "0",
                    score: 1,
                    answer_points: "",
                    options: [
                        {
                            option_no: "A",
                            option: "",
                        },
                        {
                            option_no: "B",
                            option: "",
                        },
                        {
                            option_no: "C",
                            option: "",
                        },
                        {
                            option_no: "D",
                            option: "",
                        },
                    ],
                };
                this.correctAnswer = [];
                this.$refs['multipleForm'].resetFields();
            }
        },
        watch: {
            // formData: {
            //     handler(newVal) {
            //         if (newVal) {
            //             let correctAnswer = this.correctAnswer.join("");
            //             if (correctAnswer) {
            //                 this.formData.correct_answer = correctAnswer;
            //             }
            //
            //             this.$emit('form-data', this.formData);
            //         }
            //     },
            //     deep: true
            // },

            multipleData: {
                handler(newVal) {
                    if (JSON.stringify(newVal) !== {}) {
                        for (let key in newVal) {
                            let item = newVal[key];
                            if (key === "correct_answer") {
                                if(item && item.length > 0){
                                    this.correctAnswer = newVal.correct_answer.split("");
                                }else {
                                    this.correctAnswer = [];
                                }
                            } else {
                                this.formData[key] = item;
                            }
                        }
                    }
                },
                deep: true
            }

        }
    }
</script>

<style scoped>

</style>