import { render, staticRenderFns } from "./ChangeQuestionBank.vue?vue&type=template&id=f38baa78&scoped=true&"
import script from "./ChangeQuestionBank.vue?vue&type=script&lang=js&"
export * from "./ChangeQuestionBank.vue?vue&type=script&lang=js&"
import style0 from "./ChangeQuestionBank.vue?vue&type=style&index=0&id=f38baa78&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f38baa78",
  null
  
)

export default component.exports