<template>
    <div class="row">
        <el-form
                :disabled="isReadOnly"
                ref="CaseAnalysisForm"
                :model="formData"
                :rules="rules"
        >
            <el-form-item class="row" align="center" prop="topic">
                <div class="el-col-6 flex-column">
                    <div class="input-box">
                        题目列表
                        <i v-if="!isReadOnly"
                           class="el-icon-plus chart-color line-height-54"
                           @click="onAddQuestionListClick"></i>
                    </div>
                    <div class="col-md-12 overflow-hidden input-box" v-for="(item,index) in formData.topics"
                          :key="index">
                        <i v-if="!isReadOnly && formData.topics.length > 1"
                           class="el-icon-minus chart-color-red line-height-54"
                           @click="onDeleteQuestionListClick(index)"></i>
                        <span v-if="item && item.topic" @click="onPickTopicClick(index)"> {{ item.topic }}</span>
                        <span v-else @click="onPickTopicClick(index)"> 题目</span>
                    </div>
                </div>

                <div class="el-col-18 border-solid-left" v-if="formData.topics && formData.topics[curPickIndex]">
                    <div class="el-col-24 margin-bottom">
                        <label class="el-col-4">题目</label>
                        <span class="el-col-20">
                            <el-input
                                    type="textarea"
                                    v-model="formData.topics[curPickIndex].topic"
                                    :autosize="{ minRows: 3, maxRows: 5}"
                                    :disabled="isReadOnly"
                                    required="true"
                                    placeholder="请输入题目"
                            />
                        </span>
                    </div>

                    <div v-for="(answerItem,index) in formData.topics[curPickIndex].options" :key="index">
                        <div class="row margin-bottom">
                            <label class="el-col-4">{{ '问题'+ (index+1) }}</label>
                            <div class="input-box flex-center el-col-18 margin-bottom">
                                <el-input v-model="answerItem.program"
                                          type="textarea"
                                          :autosize="{ minRows: 2, maxRows: 5}"
                                          placeholder="请输入问题"
                                ></el-input>
                            </div>
                            <i v-if="!isReadOnly && index === 0"
                               class="el-icon-plus chart-color line-height-54"
                               @click="onAddQuestionClick(index)"></i>

                            <i v-if="!isReadOnly && index !== 0"
                               class="el-icon-minus chart-color-red line-height-54"
                               @click="onDeleteQuestionClick(index)"></i>
                        </div>

                        <div class="row">
                            <label class="el-col-4">答案</label>
                            <div class="el-col-20 margin-bottom">
                                <el-input type="textarea" v-model="answerItem.correct_answer"
                                          placeholder="请输入解析内容"></el-input>
                            </div>
                        </div>

                        <div class="row" style="margin-bottom: 12px">
                            <label class="el-col-4">解析</label>
                            <div class="el-col-20 margin-bottom">
                                <el-input type="textarea" v-model="answerItem.answer_points"
                                          placeholder="请输入解析内容"></el-input>
                            </div>
                        </div>

                        <!--                        <div class="el-col-24 margin-bottom">-->
                        <!--                            <label class="el-col-4">分数</label>-->
                        <!--                            <div class="el-col-8">-->
                        <!--                                <el-input-number v-model="answerItem.score"-->
                        <!--                                                 :min="1"-->
                        <!--                                                 :max="100"-->
                        <!--                                                 label="描述文字"-->
                        <!--                                ></el-input-number>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                    </div>
                </div>
            </el-form-item>

            <el-divider><i class="el-icon-s-ticket"/></el-divider>
            <div class="total-score">
                总分：
                <el-input-number v-model="formData.score" @change="totalScoreChange" :min="1" label="总分"/>
            </div>
        </el-form>
    </div>
</template>

<script>
    export default {
        name: "CaseAnalysisQuestion",
        props: {
            isReadOnly: {
                type: Boolean,
                default: false
            },
            isShowAddIcon: {
                type: Boolean,
                default: true
            },
            caseData: {
                type: Object,
                default: () => {
                }
            },
            primaryId: {
                type: String,
                default: ""
            }
        },
        data() {
            return {
                formData: {
                    id: "",
                    question_no: "",
                    topic: "",
                    score: "0",
                    status: "",
                    created_at: "",
                    updated_at: "",
                    question_id: "",
                    topics: [
                        {
                            id: "",
                            question_id: "",
                            topic: "",
                            score: "0",
                            status: "",
                            created_at: "",
                            updated_at: "",
                            options: [
                                {
                                    id: "",
                                    topic_id: "",
                                    program: "",
                                    correct_answer: "",
                                    answer_points: "",
                                    score: "1",
                                    created_at: "",
                                    updated_at: "",
                                }
                            ]
                        }
                    ]
                },

                rules: {
                    // topic:[
                    // {required: true, message: '请输入题目内容', trigger: 'blur'}
                    // ]
                },

                curPickIndex: 0,
                caqId: "",
                totalScore: '',
            }
        },
        methods: {

            //选择当前的题干
            onPickTopicClick(index) {
                this.curPickIndex = index;
            },

            totalScoreChange(val) {
                val && this.$set(this.formData, 'score', val)
            },

            //新增题干列表
            onAddQuestionListClick() {
                if (this.formData.topics.length >= 3) {
                    this.$message("最多不能超过三道题");
                    return;
                }

                let topicObject = {
                    id: "",
                    question_id: "",
                    topic: "",
                    score: 0,
                    status: "0",
                    created_at: "",
                    updated_at: "",
                    options: [
                        {
                            id: "",
                            topic_id: "",
                            program: "",
                            correct_answer: "",
                            answer_points: "",
                            score: 0,
                            created_at: "",
                            updated_at: "",
                        }
                    ]
                };

                if (!this.isReadOnly && this.primaryId) {
                    topicObject.question_id = this.formData.question_id;
                }

                this.formData.topics.push(topicObject);
            },


            validateData() {
                // let correctAnswer = this.correctAnswer.join("");
                // if (correctAnswer) {
                //     this.formData.correct_answer = correctAnswer;
                // }
                let topics = this.formData.topics;
                this.formData.topic = topics[0].topic;

                return new Promise((resolve, reject) => {
                    this.$refs.CaseAnalysisForm.validate((valid) => {
                        console.log('formData', this.formData);
                        if (valid) {
                            resolve(this.formData);
                            // this.$emit('form-data', this.formData);
                        } else {
                            // this.$message('请填写必填项');
                            reject('请填写必填项');
                        }
                    });
                });
            },

            onAddQuestionClick() {
                let answerObject = {
                    id: "",
                    topic_id: "",
                    program: "",
                    correct_answer: "",
                    answer_points: "",
                    score: 0,
                    created_at: "",
                    updated_at: "",
                };
                let topics = this.formData.topics[this.curPickIndex];
                topics.options.push(answerObject);
            },

            onDeleteQuestionListClick(index) {
                if (this.formData.topics.length <= 1) {
                    this.$message("题目最少为1题");
                    return;
                }

                this.formData.topics.splice(index,1);
                this.curPickIndex = 0;
                console.log('index',index);
            },

            onDeleteQuestionClick(index){
                this.formData.topics[this.curPickIndex].options.splice(index, 1);
            },


            // //重置数据
            resetForm() {
                this.formData = {
                    id: "",
                    question_no: "",
                    topic: "",
                    score: "0",
                    status: "",
                    created_at: "",
                    updated_at: "",
                    question_id: "",
                    topics: [
                        {
                            id: "",
                            question_id: "",
                            topic: "",
                            score: "0",
                            status: "",
                            created_at: "",
                            updated_at: "",
                            options: [
                                {
                                    id: "",
                                    topic_id: "",
                                    program: "",
                                    correct_answer: "",
                                    answer_points: "",
                                    score: "1",
                                    created_at: "",
                                    updated_at: "",
                                }
                            ]
                        }
                    ]
                }
            }
        },
        watch: {
            // formData: {
            //     handler(newVal) {
            //         if (newVal) {
            //             let topics = this.formData.topics;
            //             // let score = 0;
            //             // for (let key in topics) {
            //             //     let topicItem = topics[key];
            //             //     let topicScore = 0;
            //             //     for (let answerKey in topics[key].options) {
            //             //         let options = topics[key].options;
            //             //         let answerScore = options[answerKey].score;
            //             //         topicScore += answerScore;
            //             //     }
            //             //
            //             //     topicItem.score = topicScore;
            //             //     score += topicScore;
            //             // }
            //             // this.formData.score = score +"";
            //
            //             this.formData.topic = topics[0].topic;
            //
            //             this.$emit('cur-data', this.formData);
            //         }
            //     }, deep: true
            // },
            caseData: {
                handler(newVal) {

                    for (let key in newVal) {
                        // if (newVal.hasOwnProperty(key)) {
                        this.formData[key] = newVal[key];


                        // }
                    }
                },
                deep: true,
                immediate: true
            }
        }
    }
</script>

<style scoped>
    .flex-column {
        display: flex;
        flex-direction: column;
    }

    .overflow-hidden {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .input-box {
        border-bottom: 1px solid whitesmoke;
        height: 54px;
        line-height: 54px;
    }

    .input-box:hover {
        cursor: pointer;
    }

    .border-solid-left {
        border-left: 1px solid whitesmoke;
    }

    .chart-color {
        color: #428bca;
        line-height: 54px;
    }

    .chart-color-red {
        color: red;
        line-height: 54px;
    }

    .margin-bottom {
        margin-bottom: 12px;
    }

    .total-score {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
</style>