<template>
    <div>
         <span class="right-notice">
           当前选择题库：
           <a @click="onChangeQuestionBankClick" class="add-style">{{qbName}}</a>
      </span>
        <el-dialog :visible.sync="isShowDialog" :close-on-click-modal="false" width="25%" title="切换题库"
                   @close="onCloseDialog" :destroy-on-close="true">
            <div>
                当前选择题库：
                <span style="margin-top: 5px"><label>{{ qbName }}</label></span>
            </div>
            <div class="input-suffix">
                选择题库：
                <el-select v-model="qbId" filterable placeholder="请选择" @change="handleQuestionBankSelect">
                    <el-option
                            v-for="item in questionBankList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="onDialogCancel">取 消</el-button>
                <el-button type="primary" @click="onModifyDialogConfirm" :disabled="curBankId == qbId || !qbId">确定
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    const WINDOW_LOCAL_ORIGIN = process.env.VUE_APP_POROXY_TARGET;
    import {mapMutations,mapGetters} from 'vuex'

    export default {
        name: "ChangeQuestionBank",
        data() {
            return {
                isShowDialog: false,
                curQbId: "",
                questionBankList: [],
                // 更改默认题库
                qbId: "",
                qbName: "",
                curBankId: ""
            }
        },
        mounted() {
            this.curBankId = this.userBankChoice ? this.userBankChoice.bank_id : "";
            this.qbName = this.userBankChoice ? this.userBankChoice.name : "";
            this.initQuestionTypeList();
        },
        computed:{
            ...mapGetters([
                'userBankChoice'
            ])
        },

        methods: {
            ...mapMutations([
                'storeUserBankChoice'
            ]),

            //获取题库选中的值
            handleQuestionBankSelect(item) {
                // this.curBankId = item;
            },

            //切换题库按钮点击
            onChangeQuestionBankClick() {
                this.isShowDialog = true;
            },

            //关闭弹框
            onCloseDialog() {
                this.qbId = "";
            },

            //对话框确认点击
            onModifyDialogConfirm() {
                // 切换题库
                if (!this.qbId) {
                    this.$message("请选择题库");
                    return;
                }

                let qbName = this.getQuestionBankName();

                this.$confirm('题库将切换到：[' + qbName + ']', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    this.isShowDialog = false;
                    this.putBankChoiceToStore(this.qbId);

                    this.qbId = "";
                    this.curBankId = "";
                    this.qbName = qbName;

                }).catch(() => {
                });
            },
            async putBankChoiceToStore(bankId) {
                let param = {
                    "qb_id" : bankId
                };
                let {info} = await this.$api.chooseQuestionBank(param);
                if (info) {
                    this.storeUserBankChoice(info)
                    this.$emit('confirm')
                }
            },

            //获取题库
            async initQuestionTypeList() {
                let {list} = await this.$api.getQuestionBankSysList();
                this.$nextTick( async () => {
                    let questionList = [];
                    for (let key in list) {
                        let item = list[key];
                        let object = {
                            "label": item.name,
                            "value": item.id,
                        };
                        questionList.push(object);
                    }
                    this.questionBankList = questionList;

                    let info = this.userBankChoice;
                    // 设置题库默认值
                    if (!info || JSON.stringify(info) === '{}') {
                        if (list.length <= 0) {
                            this.$message("题库列表为空");
                            return;
                        }

                        this.putBankChoiceToStore(list[0].id);
                    }

                });
            },

            //对话框取消点击
            onDialogCancel() {
                this.isShowDialog = false;
                this.qbId = "";
            },

            //获取题库名称
            getQuestionBankName() {
                if (!this.qbId) {
                    return ""
                }

                if (this.questionBankList.length <= 0) {
                    return ""
                }

                for (let bank of this.questionBankList) {
                    if (bank.value === this.qbId) {
                        return bank.label;
                    }
                }
            },

        }
    }
</script>

<style lang="stylus" scoped>
    .input-suffix
        margin-top 20px

    .add-style
        cursor pointer
        text-decoration underline
        color #1890ff
</style>