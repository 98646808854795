<template>
    <div class="el-row">
        <el-form
                :model="formData"
                :rules="rules"
                label-width="80px"
                ref="judgeForm"
        >

            <el-form-item label="题目" prop="topic" >
                <el-input v-model="formData.topic"
                          :readonly="isReadOnly"
                          type="textarea"
                          class="el-col-11"
                          :autosize="{ minRows: 2, maxRows: 5}"
                          placeholder="请输入题目"
                ></el-input>
            </el-form-item>

            <el-form-item label="正确答案" prop="correct_answer">

                <el-radio-group v-model="formData.correct_answer">
                    <el-radio v-for="(item, index) in formData.options" :disabled="isReadOnly" :label="item.option_no" :key="index" border>{{ item.option }}</el-radio>
                </el-radio-group>

            </el-form-item>

            <el-form-item label="解析">
                <el-input v-model="formData.answer_points"
                          type="textarea"
                          class="el-col-11"
                          :readonly="isReadOnly"
                          :autosize="{ minRows: 2, maxRows: 5}"
                          placeholder="请输入解析内容"
                ></el-input>
            </el-form-item>

            <el-form-item label="分数">
                <el-input-number v-model="formData.score"
                                 @change="onPointChange"
                                 :min="1"
                                 :max="10"
                                 label="描述文字"
                ></el-input-number>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>

    export default {
        name: "SingleChoiceContent",
        props: {
            isReadOnly: {
                type: Boolean,
                default: false
            },
            primaryId: {
                type: String,
                default: ""
            },
            judgeData: {
                type: Object,
                default: () => {
                }
            },
        },

        mounted(){},

        data() {
            return {
                formData: {
                    topic: "",
                    correct_answer: "A",
                    type: "",
                    score:"1",
                    answer_points:"",
                    options:[
                        {
                            option_no: "A",
                            option: "正确"
                        },
                        {
                            option_no: "B",
                            option: "错误"
                        }
                    ]
                },

                rules: {
                    topic: [
                        { required: true, message: '请输入题目', trigger: 'blur' }
                    ],

                    correct_answer: [
                        { required: true, message: '请输入答案', trigger: 'blur' }
                    ],
                },

                curQbId:"",

            }
        },

        methods: {
            //题库状态按钮
            onBankStatusChange(value) {
                this.formData.status = value;
            },

            //分数
            onPointChange(value) {
                this.formData.score = value;
            },

            validateData() {

                return new Promise((resolve, reject)=>{
                    this.$refs.judgeForm.validate((valid) => {
                        if (valid) {
                            resolve(this.formData);
                            // this.$emit('form-data', this.formData);
                        } else {
                            // this.$message('请填写必填项');
                            reject('请填写必填项');
                        }
                    });
                });
            },

            resetForm() {
                this.formData = {
                    topic: "",
                    correct_answer: "A",
                    type: "",
                    score:"1",
                    answer_points:"",
                    options:[
                        {
                            option_no: "A",
                            option: "正确"
                        },
                        {
                            option_no: "B",
                            option: "错误"
                        }
                    ]
                }
            }
        },
        watch: {
            // formData: {
            //     handler(newVal) {
            //         this.$emit('form-data', newVal);
            //     },
            //     deep: true
            // },

            judgeData: {
                handler(newVal) {
                    if (JSON.stringify(newVal) !== {}) {
                        let singleData = newVal;
                        for (let key in singleData) {
                            let item = singleData[key];
                            this.formData[key] = item;
                        }

                    }
                },
                deep: true,
                immediate: true
            }
        }
    }
</script>

<style scoped>
    .text-center {
        text-align: center;
    }
</style>