<template>
  <div>
    <page-header-layout>
      <div class="main-page-content">
        <el-row class="table-header" type="flex" justify="space-between">
          <el-col :span="18">
            <el-dropdown split-button type="primary" size="medium"
                         @command="onAddQuestionButtonClick"
                         v-if="userPermissions.indexOf('question_bank_question_create') !== -1 "
            >
              添加
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item, index) in typeList" :command="item.id" :key="index">
                  <span v-if="item.id !== 'all'">{{ item.text }}</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
<!--          <el-col :span="5" :offset="1">-->
<!--            <change-question-bank @confirm="onChangeQuestionBankClick"></change-question-bank>-->
<!--          </el-col>-->
        </el-row>
        <el-row class="table-search">
          <el-form :inline="true" :model="formData" class="demo-form-inline">
            <el-form-item label="题型">
              <el-select v-model="searchForm.type"
                         clearable
                         placeholder="请选择题型"
              >
                <div v-for="item in typeList" :key="item.id">
                  <div v-if="item.id !== 'all'">
                    <el-option :key="item.id"
                               :label="item.text"
                               :value="item.id"
                    ></el-option>
                  </div>
                </div>
              </el-select>
            </el-form-item>
            <el-form-item label="类型">
              <el-select v-model="businessName"
                         filterable
                         clearable
                         placeholder="请选择业务类型"
                         @change="handleBusinessSelect"
              >
                <el-option
                    v-for="item in businessTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="题目">
              <el-input placeholder="请输入题目内容"
                        v-model="searchForm.topic"
                        :clearable="true"/>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSearchClick" style="margin-left: 12px">搜索</el-button>
            </el-form-item>
            <el-form-item>
              <el-button @click="onResetSearchClick">重置</el-button>
            </el-form-item>
          </el-form>
        </el-row>

        <ApeTable ref="apeTable" :data="questionBankQuestionList" :columns="columns" :loading="loadingStatus"
                  :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row>
          <el-table-column
              slot="first-column"
              width="80"
              align="center"
              label="Drag">
            <template slot-scope="scope">
              <el-tooltip effect="dark" content="拖动排序" placement="top-start">
                <span class="drag-handle" :data-id="scope.row.id"><i class="el-icon-rank"></i></span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
              v-if="buttonType === 'icon'"
              label="操作"
              fixed="right"
              width="150"
          >
            <template slot-scope="scope">
              <span>
                    <el-tooltip effect="dark" content="查看" placement="top-start"
                                v-if="userPermissions.indexOf('question_bank_question_view') !== -1">
                                 <el-button size="mini" icon="el-icon-view"
                                            @click="viewButton(scope.row.id, scope.row.type)"></el-button>
                    </el-tooltip>
                <el-tooltip effect="dark" content="编辑" placement="top-start"
                            v-if="userPermissions.indexOf('question_bank_question_edit') !== -1">
                  <el-button size="mini" icon="el-icon-edit"
                             @click="editButton(scope.row.id, scope.row.type)"></el-button>
                </el-tooltip>
                  <el-tooltip v-if="userPermissions.indexOf('question_bank_question_delete') !== -1"
                              effect="dark"
                              content="删除" placement="top-start">
                  <span>
                  <el-button slot="reference" type="danger" size="mini"
                             icon="el-icon-delete" @click="deleteButton(scope.row.id)"></el-button>
                  </span>
                  </el-tooltip>
                          </span>
            </template>
          </el-table-column>
          <el-table-column
              v-if="buttonType === 'text'"
              label="操作"
              fixed="right"
              width="200">
            <template slot-scope="scope">
                             <span>
                                 <el-button size="mini"
                                            v-if="userPermissions.indexOf('question_bank_question_view') !== -1"
                                            @click="viewButton(scope.row.id, scope.row.type)">查看</el-button>

                  <el-button
                      v-if="userPermissions.indexOf('question_bank_question_edit') !== -1"
                      size="mini"
                      @click="editButton(scope.row.id, scope.row.type)">编辑
                  </el-button>


                  <el-button
                      v-if="userPermissions.indexOf('question_bank_question_delete') !== -1"
                      slot="reference"
                      type="danger"
                      size="mini"
                      @click="deleteButton(scope.row.id, scope)">删除
                  </el-button>
                  </span>
            </template>
          </el-table-column>
        </ApeTable>
      </div>
    </page-header-layout>
    <el-dialog :visible.sync="isShowQuestionBankDialog" width="30%" append-to-body :destroy-on-close="true">
      <div slot="title">选择题库</div>
      <div class="demo-input-suffix">
        所属题库：
        <el-select v-model="qbName" filterable placeholder="请选择" @change="handleQuestionBankSelect">
          <el-option
              v-for="item in questionBankList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>

      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="onQuestionBankDialogCancelClick">取 消</el-button>
        <el-button type="primary" @click="onQuestionBankDialogClick" :disabled="!curQbId">确定</el-button>
      </div>
    </el-dialog>
    <question-bank-question-dialog
        ref="questionDialog"
        :dialogConfig="dialogConfig"
        :isReadOnly="isReadOnly"
        :questionType="curType"
        :primaryId="primaryId"
        :questionData="curDialogFromData"
        @dialog-confirm="onDialogConfirmClick"
        @dialog-cancel="onDialogCancelClick"
    >
    </question-bank-question-dialog>
  </div>
</template>

<script>
const DEL = "del";
const NO_DEL = "no_del";
import PageHeaderLayout from '@/layouts/PageHeaderLayout';
import ApeTable from '@/components/ApeTable';
import {mapGetters} from 'vuex';
import Sortable from 'sortablejs'

import QuestionBankQuestionDialog from '@/components/questionBankQuestionDialog';
import ChangeQuestionBank from "@/components/ChangeQuestionBank"
import SearchBox from '@/components/SearchBox'

export default {
  name: "index",
  components: {
    PageHeaderLayout,
    ApeTable,
    QuestionBankQuestionDialog,
    // SingleChoiceContent,
    // MultipleChoiceContent,
    // EssayQuestionContent,
    // CaseAnalysisQuestion,
    ChangeQuestionBank,
    SearchBox
    // JudgeQuestion
  },
  data() {
    return {

      isShowQuestionBankDialog: false,
      questionBankQuestionList: [],
      loadingStatus: false,
      columns: [
        {
          title: '所属题库',
          value: 'question_bank_name',
        },
        {
          title: '业务类型',
          value: 'bt_name'
        },
        {
          title: '题目',
          value: 'topic',
          // value_alias: 'topic'
        },
        {
          title: '题型',
          value: 'type_alias',
          // value_alias: 'type'
        },
        {
          title: '分数',
          value: 'score'
        },
        {
          title: '创建时间',
          value: 'created_at'
        },
      ],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        offset: 0, // 分页的offset,序号列使用
      },
      // 表单结构
      formData: {
        name: '题库名称',
        status: '0',
      },
      typeList: [],
      //搜索表单
      searchForm: {},
      //弹框信息
      dialogConfig: {
        visible: false,//是否显示弹框
        title: '',
        width: '50%',
        loading: false,
        modal: false
      },
      curType: "",
      questionBankList: [],
      isReadOnly: false,
      curDialogFromData: {},
      primaryId: "",
      curQbId: "",
      qbName: "",
      businessName: "",
      btId: "",
      businessTypeList: [],
      topic: "",
      searchParams: [
        {
          value: 'type',
          title: '题型',
          label: '题型',
          type: 'select',
          list: [],
        },
        {
          value: 'bussiness_type',
          title: '业务类型',
          label: '题目业务类型',
          type: 'select',
          list: []
        },
        {
          value: 'topic',
          title: '题目',
          label: '题目内容',
          type: 'input'
        },
      ],
    }
  },

  watch: {
    'curChooseBankId'(newVal) {
      if (newVal) {
        this.initQuestionBankQuestionList(DEL);
      }
    }
  },
  mounted() {
    this.initQuestionBankQuestionList();
    this.initQuestionTypeList();
    this.resetBusinessTypeList();
    this.getQuestionTypeList();
  },
  methods: {

    //添加题目
    onAddQuestionButtonClick(command) {
      this.primaryId = "";
      this.isReadOnly = false;
      this.curType = command;
      this.dialogConfig.visible = true;
      this.curDialogFromData = {};
      this.typeList.forEach((item) => {
        if (item.id === this.curType) {
          this.dialogConfig.title = '新增' + item.text;
        }
      });
      // this.resetForm();
      this.$nextTick(() => {
        this.$refs.questionDialog.resetForm();
      })
    }
    ,

    //切换页码
    switchPaging() {
      this.initQuestionBankQuestionList();
    }
    ,

    //响应查看按钮
    async viewButton(id, type) {
      this.curType = type;
      this.isReadOnly = true;
      this.dialogConfig.visible = true;
      this.typeList.forEach((item) => {
        if (item.id === this.curType) {
          this.dialogConfig.title = '查看' + item.text + '(ID:' + id + ')';
        }
      });
      let {info} = await this.$api.getQuestionBankQuestionInfo(id);

      this.curDialogFromData = {};
      this.curDialogFromData = info;
      this.dialogConfig.loading = false;
    }
    ,

    // 响应编辑按钮
    async editButton(id, type) {
      this.primaryId = id;
      this.curType = type;
      this.isReadOnly = false;
      this.dialogConfig.visible = true;
      this.typeList.forEach((item) => {
        if (item.id === this.curType) {
          this.dialogConfig.title = '编辑' + item.text + '(ID:' + id + ')';
        }
      });
      let {info} = await this.$api.getQuestionBankQuestionInfo(id);
      this.curDialogFromData = {};
      this.curDialogFromData = info;
    }
    ,

    // 相应删除按钮
    async deleteButton(id, scope) {
      this.$confirm('是否删除该题目?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let isSuccess = await this.$api.deleteQuestionBankQuestion(id);
        this.$nextTick(() => {
          this.initQuestionBankQuestionList('del')
        });
        if (isSuccess) {
          this.$message.success("删除成功");
        } else {
          this.$message.error("删除失败");
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
    ,

    // 表格拖拽排序，同层级移动有效果
    dragSort() {
      const el = document.querySelectorAll('.el-table__body-wrapper > table > tbody')[0];
      this.sortable = Sortable.create(el, {
        handle: ".drag-handle",
        setData: function (dataTransfer) {
          dataTransfer.setData('Text', '')
        },
        onEnd: () => {
          let Ids = [];
          let tmp = el.querySelectorAll('.drag-handle');
          for (let i = 0, len = tmp.length; i < len; i++) {
            Ids.push(tmp[i].dataset.id)
          }
          this.dragSortSubmit(Ids)
        },
      })
    }
    ,

    // 拖拽排序数据提交，请求接口
    async dragSortSubmit() {
      // let info = await this.$api.orderCarousel(orderIds)
      // if (info == 'ok') {
      //     this.$nextTick(() => {
      //         this.initCarouselList()
      //     })
      // }
      this.$message.success('保存成功!')
    }
    ,

    //初始化题目数据
    async initQuestionBankQuestionList(type, params = {}) {
      this.loadingStatus = true;
      let inputData = this.$refs['apeTable'].getPagingInfo(type);

      for (let param in this.searchForm) {
        inputData[param] = this.searchForm[param];
      }

      if (this.btId) {
        inputData.bt_id = this.btId;
      }

      // let qbId = this.curChooseBankId;
      // if (!qbId) {
      //     let userInfo = JSON.parse(localStorage.getItem('user_info'));
      //     let name = WINDOW_LOCAL_ORIGIN + '_' + userInfo.id +'_'+'qb_info';
      //     let qbInfo = JSON.parse(localStorage.getItem(name));
      //     inputData.qb_id = qbInfo.id;
      // } else {
      inputData.qb_id = this.curChooseBankId;
      // }

      let {list, pages} = await this.$api.getQuestionBankQuestionList(inputData);
      this.questionBankQuestionList = [];
      this.$nextTick(() => {
        this.questionBankQuestionList = list
      });
      this.pagingData.total = pages.total;
      this.pagingData.offset = pages.offset;
      this.loadingStatus = false
    }
    ,

    //获取题库
    async initQuestionTypeList() {
      let {list} = await this.$api.getQuestionBankList();
      this.$nextTick(() => {
        let questionList = [];
        for (let key in list) {
          let item = list[key];
          let object = {
            "label": item.name,
            "value": item.id,
          };
          questionList.push(object);
        }
        this.questionBankList = questionList;

      });
    }
    ,

    //弹框确认按钮
    async onDialogConfirmClick(formData) {
      if (this.isReadOnly) {
        this.dialogConfig.visible = false;
        return;
      }

      if (this.primaryId) {
        let isSuccess = await this.$api.updateQuestionBankQuestion(formData);
        isSuccess ? this.$message.success('修改成功') : this.$message.error('修改失败')
      } else {
        formData.type = this.curType;
        formData.qb_id = this.curChooseBankId;
        let isSuccess = await this.$api.insertQuestionBankQuestion(formData);
        if (isSuccess) {
          this.$message.success('添加成功');
          this.$nextTick(() => {
            this.$refs.questionDialog.resetForm();
          })
        } else {
          this.$message.error('添加失败');
        }

      }
      this.dialogConfig.loading = false;
      this.curQbId = "";
      this.qbName = "";

      //关闭弹框
      this.dialogConfig.visible = false;
      this.curDialogFromData = {};
      this.initQuestionBankQuestionList(DEL);
    }
    ,

    onChangeQuestionBankClick() {
      this.initQuestionBankQuestionList(DEL);
      this.getQuestionTypeList();
    }
    ,

    //弹框取消按钮
    onDialogCancelClick() {
      this.dialogConfig.visible = false;
      this.curQbId = "";
      this.qbName = "";
      this.$nextTick(() => {
        this.$refs.questionDialog.resetForm();
      })
    }
    ,

    //获取到弹框表单信息
    getDialogFormData(value) {
      this.curDialogFromData = value;
    }
    ,

    onSearchClick() {
      this.$refs['apeTable'].resetCurPageSize();
      this.initQuestionBankQuestionList(DEL, this.searchForm);
    }
    ,

    onResetSearchClick() {
      this.$refs['apeTable'].resetCurPageSize();
      this.searchForm = {};
      this.curQbId = "";
      this.btId = "";
      this.businessName = "";
      this.initQuestionBankQuestionList(DEL);
    }
    ,

    onQuestionBankDialogClick() {
      if (!this.curQbId) {
        this.$message("请选择题库");
        return;
      }
      this.isShowQuestionBankDialog = false;
      this.dialogConfig.visible = true;
      this.curDialogFromData = {};
      this.typeList.forEach((item) => {
        if (item.id === this.curType) {
          this.dialogConfig.title = item.text;
        }
      });

      this.$nextTick(() => {
        this.$refs.questionDialog.resetForm();
      })
    }
    ,

    //获取类型
    async resetBusinessTypeList() {
      let {list} = await this.$api.getBusinessTypeList();
      this.$nextTick(() => {
        let businessList = [];
        for (let key in list.data) {
          let item = list.data[key];
          let object = {
            "label": item.name,
            "value": item.id,
          };
          businessList.push(object);
        }
        this.businessTypeList = businessList;

      });
    }
    ,

    //获取题型
    async getQuestionTypeList() {
      this.typeList = await this.$api.commonDict('question_category');
    }
    ,

    onQuestionBankDialogCancelClick() {
      this.isShowQuestionBankDialog = false;
      this.curQbId = "";
      this.qbName = "";
      this.btId = "";
      this.businessName = "";
    }
    ,

    handleQuestionBankSelect(item) {
      this.curQbId = item;
    }
    ,

    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    }
    ,

    handleBusinessSelect(item) {
      this.btId = item;
    }
    ,


  }
  ,
  computed: {
    ...mapGetters(['userPermissions', 'buttonType', 'questionBankInfo', 'curChooseBankName', 'curChooseBankId'])
  }
  ,

}
</script>

<style lang="stylus" scoped>
.buttonGroup
  display: flex
  flex-direction column

.table-header
  margin-bottom 12px
</style>